<template>
  <form-wizard
    id="wizard"
    class="wizard wizard-primary sw sw-theme-default sw-justified"
    color="#3f80ea"
    @on-complete="save(true)"
    @on-save="save(false)"
    :hideSaveButton="isNew"
    :finishButtonText="isNew ? 'Create' : 'Save & Exit'"
  >
    <tab-content title="Details" description="Specify configuration details">
      <form>
        <b-form-group label="Name">
          <b-form-input
            type="text"
            placeholder="e.g. My New Data"
            v-model="data.name"
            required
            trim
          />
        </b-form-group>
        <b-form-group label="Description">
          <b-form-textarea
            v-model="data.description"
            placeholder="e.g. This is a description of my new data..."
            rows="4"
            max-rows="4"
          ></b-form-textarea>
        </b-form-group>
      </form>
    </tab-content>
    <tab-content title="Fields" description="Customize field layout">
      <b-container>
        <b-row>
          <b-col>
            <b-card class="field-card">
              <b-card-title>
                Fields
                <b-button
                  size="sm"
                  variant="primary"
                  class="float-right card-title-button"
                  @click="addField"
                  >Add Field</b-button
                >
              </b-card-title>
              <b-table
                striped
                :items="data.fields"
                :fields="fields"
                class="no-body-margin"
                tbody-tr-class="cursor-pointer"
              >
                <template #cell(select)="data">
                  <b-form-checkbox v-model="selectedFields" :value="data.index">
                  </b-form-checkbox>
                </template>
                <template #cell(name)="data">
                  <b-form-input
                    v-model="data.item.name"
                    placeholder="e.g. Field 1"
                  ></b-form-input>
                </template>
                <template #cell(type)="data">
                  <b-form-select
                    v-model="data.item.type"
                    :options="types"
                  ></b-form-select>
                </template>
                <template #cell(required)="data">
                  <b-form-checkbox
                    v-model="data.item.required"
                    :value="true"
                    :unchecked-value="false"
                    switch
                  >
                  </b-form-checkbox>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deleteField(data.index)"
                    ><i class="far fa-trash-alt"></i
                  ></b-button>
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </tab-content>
    <tab-content title="Data" description="Input or update data">
      <b-alert :show="alert.show" :variant="alert.type">
        <div class="alert-message">
          <i class="far fa-check-circle" v-if="alert.type === 'success'"></i>
          <i
            class="fas fa-exclamation-circle"
            v-if="alert.type === 'danger'"
          ></i>
          {{ alert.message }}
        </div>
      </b-alert>
      <small class="text-muted"
        >Input JSON data into records array.
        <b-button
          variant="warning"
          class="float-right mb-1"
          size="sm"
          @click="performValidation"
          ><i class="far fa-check-circle"></i> Validate</b-button
        >
      </small>
      <prism-editor
        v-model="json"
        language="json"
        line-numbers
        :highlight="highlighter"
        class="prism-editor mt-1"
      ></prism-editor>
    </tab-content>
  </form-wizard>
</template>

<script>
import FormWizard from "@/components/CustomFormWizard";
import TabContent from "@/components/CustomTabContent";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-tomorrow.css";

export default {
  name: "DataEditor",
  inject: ["notyf"],
  components: {
    FormWizard,
    TabContent,
    PrismEditor,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    records: {
      type: Object,
      required: true,
    },
    sources: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      bind: ["slugs"],
      fieldIndex: null,
      field: {},
      fields: [
        {
          key: "select",
          label: "",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "required",
          label: "Required",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          sortable: true,
        },
      ],
      types: [
        {
          text: "Number",
          value: "number",
        },
        {
          text: "String",
          value: "string",
        },
        {
          text: "Object",
          value: "object",
        },
        {
          text: "Array",
          value: "array",
        },
        {
          text: "Boolean",
          value: "boolean",
        },
      ],
      selectedFields: [],
      json: "",
      alert: {
        show: false,
        type: "success",
        message: "",
      },
    };
  },
  computed: {
    fieldType() {
      switch (this.field.type) {
        case "fake":
          return "Fake Data";
        case "stored":
          return "Stored Data";
        case "formula":
          return "Formula";
        default:
          return "";
      }
    },
    isNew() {
      return !("path" in this.data);
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(data) {
        if (!("fields" in data) || !Array.isArray(data.fields)) {
          this.data.fields = [];
        }
      },
    },
    records: {
      immediate: true,
      handler(records) {
        if (!("records" in records) || !Array.isArray(records.records)) {
          this.records.records = [];
        }
        this.json = JSON.stringify(this.records, null, 2);
      },
    },
  },
  methods: {
    performValidation() {
      const result = this.validate();
      console.log("Result", result);
      if (result.valid) {
        this.alert.type = "success";
        this.alert.message = "Validation successful";
      } else {
        this.alert.type = "danger";
        this.alert.message = result.message;
      }
      this.$set(this.alert, "show", true);
    },
    validate() {
      let data;
      let result = {};
      // Check that JSON can be parsed
      try {
        data = JSON.parse(this.json);
      } catch (e) {
        result.message = "JSON data is invalid";
        result.valid = false;
        return result;
      }
      // Check that records array is present
      if (!("records" in data) || !Array.isArray(data.records)) {
        result.message = "Records array is missing";
        result.valid = false;
        return result;
      }
      // Check that records match field list
      let invalid = [];
      const fields = this.data.fields.map((field) => {
        return field.name;
      });
      let types = {};
      this.data.fields.forEach((field) => {
        types[field.name] = field.type;
      });
      data.records.forEach((record, index) => {
        if (!record || typeof record !== "object") {
          invalid.push(index);
        } else {
          Object.keys(record).forEach((field) => {
            if (!fields.includes(field)) {
              invalid.push(index);
            } else {
              const specified = types[field];
              const actual = typeof record[field];
              switch (specified) {
                case "number":
                  if (specified !== actual) {
                    invalid.push(index);
                  }
                  break;
                case "string":
                  if (specified !== actual) {
                    invalid.push(index);
                  }
                  break;
                case "object":
                  if (specified !== actual) {
                    invalid.push(index);
                  }
                  break;
                case "array":
                  if (!Array.isArray(record[field])) {
                    invalid.push(index);
                  }
                  break;
                case "boolean":
                  if (record[field] !== true && record[field] !== false) {
                    invalid.push(index);
                  }
                  break;
              }
            }
          });
        }
      });
      if (invalid.length > 0) {
        result.message =
          "The following records are invalid or include invalid fields: " +
          invalid.toString();
        result.valid = false;
        return result;
      } else {
        result.valid = true;
        return result;
      }
    },
    save(exit = false) {
      const result = this.validate();
      if (result.valid) {
        const records = JSON.parse(this.json);
        this.records.records = [...records.records];
        this.data.size = this.getDataSize(this.records);
        if (exit) {
          this.$emit("save-and-exit", {
            data: this.data,
            records: this.records,
          });
        } else {
          this.$emit("save", { data: this.data, records: this.records });
        }
      } else {
        this.notyf.error("JSON data is invalid");
      }
    },
    addField() {
      const fields = [...this.data.fields, { type: "fake" }];
      this.$set(this.data, "fields", fields);
    },
    deleteField(index) {
      let fields = [...this.data.fields];
      fields.splice(index, 1);
      this.$set(this.data, "fields", fields);
    },
    highlighter(code) {
      return highlight(code, languages.json);
    },
    getDataSize(data) {
      if (!data) {
        data = {
          records: [],
        };
      }
      const json = JSON.stringify(data);
      const size = (json.length - 14) / 1024;
      return size.toFixed(2);
    },
  },
};
</script>

<style lang="css" scoped>
.field-card {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 10%) !important;
}

.no-body-margin {
  margin-left: -20px;
  margin-right: -20px;
  width: 107.5%;
}

.card-title-button {
  margin-top: -5px;
}

.list-header-button {
  width: 107%;
  margin-left: -16px !important;
  margin-right: -16px !important;
  text-align: left;
}

.list-method {
  padding-top: 0;
  padding-bottom: 0;
}

.prism-editor {
  height: 500px;
  border: 1px solid #545968;
  border-radius: 0.25rem;
  background: #363d4f;
}
</style>
