<template>
  <data-editor
    :data="data"
    :records="records"
    :sources="sources"
    v-on:save-and-exit="create"
  ></data-editor>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import DataEditor from "@/components/DataEditor";

export default {
  name: "DataNew",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    DataEditor,
  },
  data() {
    return {
      project: {},
      data: {
        name: "",
        description: "",
        fields: [],
        data: {
          records: [],
        },
      },
      records: {
        records: [],
      },
      sources: [],
      root: ["folders", "data", ""],
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    userUid() {
      return this.userProfile.uid;
    },
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        this.bindObject("selected", uid, "project");
      },
    },
    project: {
      immediate: true,
      handler(project) {
        if (project.objectID) {
          this.root[2] = project.objectID;
          console.log("Project", project.objectID, this.$route.query.path);
          if (this.$route.query.path !== undefined) {
            this.setRootFromPath(this.$route.query.path);
          }
        }
      },
    },
  },
  methods: {
    create() {
      let data = { ...this.data };
      data.path = this.root.join("/");
      const records = { ...this.records };

      this.createObjectKey("data/" + this.project.objectID)
        .then((key) => {
          Promise.all([
            this.updateObject("data/" + this.project.objectID, key, data),
            this.updateObject(this.root.join("/"), key, data),
            this.updateObject("records/" + this.project.objectID, key, records),
          ])
            .then(() => {
              this.notyf.success("Data store created successfully");
              this.navigate("data-edit", { id: key });
            })
            .catch((error) => {
              console.log(error);
              this.notyf.error("An error occurred while saving the data store");
            });
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error("An error occurred while saving the data store");
        });
    },
    async setRootFromPath(routePath) {
      console.log("Route Path: " + routePath);
      const segments = routePath.split("/");
      let root = ["folders", "data", this.project.objectID];
      for (const segment of segments) {
        const snapshot = await this.getObjectSnapshot(
          root.join("/"),
          null,
          true
        );
        const folder = snapshot.val();
        console.log("Folder", folder);
        if (folder && "folders" in folder) {
          Object.keys(folder.folders).forEach((subkey) => {
            const subfolder = folder.folders[subkey];
            if (subfolder.slug === segment.toLowerCase()) {
              root.push("folders", subkey);
            }
          });
        }
      }
      this.root = [...root, "items"];
    },
  },
};
</script>

<style lang="css" scoped>
.field-card {
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 10%) !important;
}

.no-body-margin {
  margin-left: -20px;
  margin-right: -20px;
  width: 107.5%;
}

.card-title-button {
  margin-top: -5px;
}

.list-header-button {
  width: 107%;
  margin-left: -16px !important;
  margin-right: -16px !important;
  text-align: left;
}

.list-method {
  padding-top: 0;
  padding-bottom: 0;
}

.prism-editor {
  height: 500px;
  border: 1px solid #545968;
  border-radius: 0.25rem;
  background: #363d4f;
}
</style>
